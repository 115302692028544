import { useRef, useState } from "react";
import { TextField } from "../components/Inputs";
import { BASE_URL } from "../constants";
import axios from "axios";
import { AlertNotification } from "../components/AlertNotification";

export const LogInByPhone = () => {
    const [phone, setPhone] = useState();
    const [role, setRole] = useState();
    const [passcode, setPasscode] = useState();
    const [showPassCodeField,setShowPassCodeField] = useState(false);
    const alert = useRef();

    function userLogInByPhone() {
        if (phone.length<=9) {
            alert.current.showAlert("Enter valid phone number / Namba haitambuliki","fail");
            console.log("Enter valid phone number");
            return;
        }
        let data = {
            phone: phone,
            passcode: passcode,
            role: role,
        }
        axios.post(BASE_URL+'/auth/login-by-phone',data)
        .then((resp)=>{
            alert.current.showAlert(resp.data.message,"success");
            phone && localStorage.setItem("phone", phone);
            localStorage.setItem("user", JSON.stringify(resp.data.user));
            console.log(phone);
            window.location.reload();
        })
        .catch((error)=>{
            alert.current.showAlert("Invalid Phone Or Passcode","fail")
            console.log(error.message);
        });
    }
    return (
        <div>
            <AlertNotification ref={alert}/>
            <form action="" onSubmit={(e) => {
                userLogInByPhone();
                e.preventDefault();
            }}>
                <div className="mb-3">
                    <label className="text-pink-600 mb-3">Please enter phone number to continue / Ingiza namba yako ya simu kuendelea</label>
                    <TextField className="w-[100%]" required={true} placeholder={"Phone / Simu"} onChange={async (e) => {
                        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
                        setPhone(e.currentTarget.value);
                        setShowPassCodeField(false);
                        await axios.get(BASE_URL+"/auth/user-details?phone="+e.currentTarget.value)
                        .then((resp)=>{
                            if (resp.data?.role=="admin" || resp.data?.role=="seller") {
                                setRole(resp.data?.role);
                                setShowPassCodeField(true);
                            }
                        })
                        .catch((error)=>{
                            console.log(error.message);
                        });
                    }} />
                </div>
                {showPassCodeField&&<div className="mb-3">
                    <TextField className="w-[100%]" required={true} placeholder={"Pass Code"} onChange={(e)=>{
                        setPasscode(e.currentTarget.value);
                    }}/>
                    </div>}
                <div className="flex justify-center mb-3">
                    <button className="btn-primary">Submit</button>
                </div>
            </form>
        </div>
    );
}