import { useEffect, useRef, useState } from "react";
import { TextField } from "../components/Inputs";
import { BASE_URL, CATEGORIES, ImageLoader } from "../constants";
import axios from "axios";
import { AlertNotification } from "../components/AlertNotification";
import { SelectDropdown } from "../components/SelectDropdown";

export const NewItem = (props) => {
    const alert = useRef();
    const imagePreview = useRef();
    const [itemValues, setItemValues] = useState();
    const [message, setMessage] = useState();

    async function submitItem(e) {
        let data = new FormData();
        data.append("category", itemValues?.category);
        data.append("item_name", itemValues?.item_name);
        data.append("uom", itemValues?.uom);
        data.append("file", itemValues?.photo);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BASE_URL + '/items/new-item',
            headers: { 'Authorization': 'Bearer ', 'content-type': 'multipart/form-data' },
            data: data
        };
        await axios.request(config).then((resp) => {
            alert.current.showAlert(resp.data.message, resp.data.status);
            typeof props.setLoading != "undefined" && props.setLoading(true);
        }).catch((e) => {
            setMessage(e.message);
            console.error(e.message);
        });
    }

    async function updateItem(e) {
        let data = new FormData();
        data.append("item_id", itemValues?.item_id);
        data.append("category", itemValues?.category);
        data.append("item_name", itemValues?.item_name);
        data.append("uom", itemValues?.uom);
        data.append("price", itemValues?.price);
        data.append("file", itemValues?.photo);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BASE_URL + '/items/update-item/' + itemValues?.item_id,
            headers: { 'Authorization': 'Bearer ', 'content-type': 'multipart/form-data' },
            data: data
        };
        await axios.request(config).then((resp) => {
            alert.current.showAlert(resp.data.message, resp.data.status);
            typeof props.setLoading != "undefined" && props.setLoading(true);
        }).catch((e) => {
            setMessage(e.message);
            console.error(e.message);
        });
    }

    useEffect(() => {
        setItemValues({ 
            ...props.item,
            imagePreview: ImageLoader(props.item?.photo) });
        console.log(props.item);
    }, [props]);

    return (
        <div>
            <AlertNotification ref={alert} />
            <form method="post" enctype='multipart/form-data' onSubmit={(e) => {
                props.action === "update" ? updateItem(e) : submitItem(e);
                e.preventDefault();
            }} >
                {message && <div className="">{message}</div>}
                <div className="grid grid-cols-2 gap-1 mb-3">
                    <div>
                        <label htmlFor="">Category</label>
                        <SelectDropdown
                            defaultValue={CATEGORIES.find((e)=>e.name===props.item?.category)}
                            value={CATEGORIES.find((e)=>e===itemValues?.category)}
                            onChange={(e)=>{
                                setItemValues({ ...itemValues, category: e?.value });
                            }}
                            options={CATEGORIES.map((category) => ({
                                label: category.label,
                                value: category.name,
                            }))}
                        />
                    </div>
                    <div>
                        <label htmlFor="">Item Name</label>
                        <TextField className={"w-[100%]"} name={"item_name"} value={itemValues?.item_name} required={true} onChange={(e) => {
                            setItemValues({ ...itemValues, item_name: e.currentTarget.value });
                            console.log(itemValues);
                        }} placeholder={"Item Name"} />
                    </div>
                    <div>
                        <label htmlFor="">UOM</label>
                        <TextField className={"w-[100%]"} name={"uom"} value={itemValues?.uom} required={true} onChange={(e) => {
                            setItemValues({ ...itemValues, uom: e.currentTarget.value });
                            console.log(itemValues);
                        }} placeholder={"UOM"} />
                    </div>
                    <div>
                        <label htmlFor="">Price</label>
                        <TextField className={"w-[100%]"} name={"price"} value={itemValues?.price} placeholder={"Price"} required={true} onChange={(e) => {
                            e.currentTarget.value = Number.isNaN(parseFloat(e.currentTarget.value)) ? '' : e.currentTarget.value.replace(/[^0-9.]/g, '');
                            setItemValues({ ...itemValues, price: e.currentTarget.value });
                        }} />
                    </div>
                    <div>
                        {itemValues?.imagePreview && <img ref={imagePreview} className="rounded-md w-[130px] h-[75px]" src={itemValues?.imagePreview} />}
                        <label htmlFor="">Photo</label>
                        <TextField className={"w-[100%]"} accept={"image/*"} type={"file"} name={"file"} onChange={(e) => {
                            if (e.target.files[0] != undefined) {
                                if (e.target.files[0]?.size >= 4292408) {
                                    console.error("Image is too large");
                                    alert.current.showAlert("Image is too large", "fail");
                                    return;
                                }
                                if (e.target.files[0]?.type.split("/")[0] != "image" && e.target.files[0]?.type != undefined) {
                                    console.error("Image not valid - " + e.target.files[0]?.type);
                                    alert.current.showAlert("Please upload images only", "fail");
                                    return;
                                }
                                URL.revokeObjectURL(imagePreview.current.src);
                                setItemValues({ ...itemValues, photo: e.target.files[0], imagePreview: URL.createObjectURL(new Blob([e.target.files[0]], { type: 'image/*' })) });
                            }
                        }} placeholder={"PHOTO"} />
                    </div>
                </div>
                <div className="flex justify-center mb-3">
                    <button className="btn-primary">{props.action === "update" ? "Save Changes" : "Submit"}</button>
                </div>
            </form>
        </div>
    );
}