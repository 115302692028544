import { Component, useEffect, useRef } from "react";
import { useState } from "react";
import { TextField } from "./Inputs";
// import { InputLabel, MenuItem, Select } from "@mui/material";
import ReactSelect from "react-select"
import { PRIMARY_COLOR } from "../constants";
const styles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#fff" : "#000",
    backgroundColor: state.isFocused ? PRIMARY_COLOR : "#fff",
    cursor: "pointer",
  }),
  control: base => ({
    ...base,
    border: "1px solid rgb(148 163 184)",
    boxShadow: 'none'
  })
  // control: (provided, state) => ({
  //   width: "100%",
  //   height: "32px",
  //   padding: "0",
  //   display: "flex",
  //   flexDirection: "row",
  //   backgroundColor: "transparent",
  //   border: "none",
  //   cursor: state.isDisabled ? "not-allowed" : "pointer",
  //   opacity: state.isDisabled ? 0.7 : 1,
  // }),
  // valueContainer: (provided, state) => ({
  //   ...provided,
  //   padding: 0,
  //   height: "32px",
  // }),
  // singleValue: (provided, state) => {
  //   return {
  //     ...provided,
  //     opacity: state.isDisabled ? 0.85 : 1,
  //     transition: "opacity 300ms",
  //     margin: 0,
  //   };
  // },
  // indicatorsContainer: (provided, state) => ({
  //   ...provided,
  //   height: "32px",
  // }),
  // clearIndicator: (provided, state) => ({
  //   ...provided,
  //   padding: "0 4px",
  //   color: "#000",
  // }),
  // dropdownIndicator: (provided, state) => ({
  //   ...provided,
  //   padding: "0 4px",
  //   color: "#000",
  // }),
  // indicatorSeparator: (provided, state) => ({
  //   ...provided,
  //   display: "none",
  // }),
  // menu: (provided, state) => ({
  //   ...provided,
  //   left: "0",
  //   width: "100%",
  //   zIndex: 9999,
  //   top: "calc(100% - 4px)",
  //   boxShadow: "0 2px 3px #ccc, 0 -1px 2px #ccc",
  //   border: "none",
  // }),
}
export class SelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value:props.value,
      options:props.options,
      isSelected:false,
    }
  }

  componentDidMount(){
  }

  componentDidUpdate(prevProps,prevState){
    if (prevProps.options!==this.props.options) {
      this.setState({
        options:this.props.options,
      })
    }
    if (prevProps.value!==this.props.value) {
      this.setState({
        value:this.props.value,
        isSelected:true,
      })
    }
  }
    
  error(text) {
    this.setState({
      text:text,
    })
  }
  
  render() {
    return (
      <>
        {(this.props.label) && <label className="text-slate-600 mb-[0px]">{this.props.label} {this.props.required && <span className={"text-red-400"}>*</span>}</label>}
        <ReactSelect
          ref={this.props.ref}
          isClearable={this.props.isClearable}
          defaultValue={this.props.defaultValue}
          value={this.state.value}
          inputValue={this.state.value}
          options={this.state.options}
          onChange={(e) => {
            this.setState({
              isSelected:e?.value?true:false,
            })
            this.props.onChange(e)
          }}
          theme={(theme)=>({
            ...theme,
            borderRadius:"4px",
            colors:{
              ...theme.colors,
              primary:"rgb(71 85 105)",
              primary25:"#D7FFF1",
            }
          })}
          styles={styles}
          placeholder={this.props.placeholder}
        />
        {this.state.text && !this.state.isSelected && <div className="text-red-500 text-[0.8rem]">{this.state.text}</div>}
      </>
    );
  }
}

const SearchableDropdown = (props) => {
  const width = "w-[100%] md:w-[400px]";
  const [text, setText] = useState();
  const [selectValue, setSelectValue] = useState();
  const [selected, setSelected] = useState(0);
  const dropdownRef = useRef();
  const inputRef = useRef();
  const [showList, setShowList] = useState(false);
  const [item, setItems] = useState(false);

  const items = () => {
    return props.items.filter((item) => {
      if ((text && item[props.rows["label"]] && item[props.rows["label"]]?.toLowerCase().includes(text.toLowerCase())) || (!text)) return true;
      return false;
    });
  }

  function outsideClicked() {
    document.addEventListener("click", (e) => {
      if (dropdownRef.current && !dropdownRef?.current?.contains(e.target)) {
        setSelected(0);
        setShowList(false);
      } else {
        setShowList(true);
      }
    });
    console.log("outside");
  }

  function arrowClicked(e) {
    if (e.keyCode === 38) {
      if (selected <= 0) {
        setSelected(items().length - 1);
        return;
      }
      setSelected(selected - 1);
      console.log("up" + selected);
    } else if (e.keyCode === 40) {
      if ((selected + 1) == items().length) {
        setSelected(0);
        return;
      }
      setSelected(selected + 1);
      console.log("down" + selected);
    } else if (e.keyCode === 13) {
      items().filter((item, index) => {
        if (selected == index) {
          setText(item[props.rows["label"]]);
          setSelectValue(item[props.rows["value"]]);
          props.onChange(e, item[props.rows["value"]]);
          setShowList(false);
          return true;
        } else {
          return false;
        }
      });
      console.log("enter" + selected);
    }
  }

  useEffect((e) => {
    !props.value && setText("") && setSelectValue("");

    items().filter((item, index) => {
      if (props.value?.toString().toLowerCase() === item[props.rows["value"]].toString().toLowerCase()) {
        setText(item[props.rows["label"]]);
        setSelectValue(item[props.rows["value"]]);
      }
    });

    outsideClicked();
  }, [outsideClicked]);

  return (
    <div ref={dropdownRef} className="select-dropdown w-4/12">
      <TextField className={'hidden'} value={selectValue} />
      <TextField
        ref={inputRef}
        value={text}
        placeholder={props.placeholder}
        className={width}
        onKeyDown={arrowClicked}
        onFocus={() => setShowList(true)}
        onChange={(e) => {
          setShowList(true);
          setText(e.currentTarget.value);
          !e.currentTarget.value && setSelectValue("");
          props.onChange(e, e.currentTarget.value);
        }} />
      {showList &&
        <div className={"items absolute " + width}>
          {items().map((item, index) => <div className={selected == index ? "selected" : "un-selected"} onClick={(e) => {
            setText(item[props.rows["label"]]);
            setSelectValue(item[props.rows["value"]]);
            props.onChange(e, item[props.rows["value"]]);
            setShowList(false);
          }}>{item[props.rows["label"]]}</div>)}

          {!items().length && <div>No Data</div>}
        </div>
      }
    </div>
  );
}
export default SearchableDropdown;
