export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export function ImageLoader(image) {
    return (BASE_URL+'/items/file?file='+image);
}

export const CATEGORIES = [
    {
        name:"dry_goods",
        label:"DRY GOODS",
        icon:"",
    },
    {
        name:"fresh_fruits",
        label:"Fresh Fruits",
        icon:"",
    },
    {
        name:"fresh_vegetables",
        label:"Fresh Vegetables",
        icon:"",
    },
    {
        name:"beverages",
        label:"Beverages",
        icon:"",
    },
    {
        name:"spies",
        label:"Spies",
        icon:"",
    },
    {
        name:"fresh_fish",
        label:"Fresh Fish",
        icon:"",
    },
    {
        name:"meat",
        label:"Meat",
        icon:"",
    }
];

export const PRIMARY_COLOR = "rgb(219 39 119)";

export const products = [
  
    {
          "name":"Cabbage",
          "photo_url":"https://www.freepngimg.com/thumb/categories/2970.png",
          "quantity":"One Unit",
          "id":2,
          "price": 30
      },
      {
          "name":"Capsicum",
          "photo_url":"https://www.nicepng.com/png/detail/52-525615_green-bell-pepper-png-green-capsicum-png.png",
          "quantity":"One Unit",
          "id":7,
          "price": 5
      },
      {
          "name":"Garlic",
          "photo_url":"https://www.freepngimg.com/thumb/garlic/2-2-garlic-transparent-thumb.png",
          "quantity":"One Unit",
          "id":10,
          "price": 20
      },
      {
          "name":"Beetroot",
          "photo_url":"https://pngimg.com/uploads/beet/beet_PNG28.png",
          "quantity":"One Unit",
          "id":11,
          "price": 20
      },
      {
          "name":"Tomatoes",
          "photo_url":"https://www.freepngimg.com/thumb/categories/2985.png",
          "quantity":"One Unit",
          "id":13,
          "price": 5
      },
      {
          "name":"Celeriac",
          "photo_url":"https://w7.pngwing.com/pngs/252/146/png-transparent-celeriac-leaf-vegetable-food-celery-herbes-leaf-vegetable-food-plant-stem-thumbnail.png",
          "quantity":"One Bunch",
          "id":16,
          "price": 5
      },
      {
          "name":"Carrots",
          "photo_url":"https://www.freepngimg.com/thumb/categories/2971.png",
          "quantity":"One Kg",
          "id":18,
          "price": 60
      },
      {
          "name":"Onions",
          "photo_url":"https://www.freepngimg.com/thumb/onion/10-red-onion-png-image-thumb.png",
          "quantity":"One Kg",
          "id":19,
          "price": 120
      },
      {
          "name":"Potatoes",
          "photo_url":"https://www.freepngimg.com/thumb/potato/7-potato-png-images-pictures-download-thumb.png",
          "quantity":"One container",
          "id":20,
          "price": 80
      }
  ]